import React, { useEffect, useState } from 'react';
import QuestionJSON from './Tip.json'
import ReactPaginate from 'react-paginate';
import Table from '../../components/Atoms/Table/Table';
import { useDispatch, useSelector } from 'react-redux';
import { questionAction, userActions } from '../../_actions';
import AddModel from './Components/AddModel';
import EditModel from './Components/EditModel';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { IoIosSearch } from "react-icons/io";
import Loader from '../../components/Loader/Loader';
const Tip = () => {


  let covertdata = [];
  const [keyWord, setkeyWord] = useState("")
  const [pageNo, setpageNo] = useState(1)
  const [size, setsize] = useState(10)
  const [currentPage, setCurrentPage] = useState(0)
  const [offset, setoffset] = useState(0)
  // Search---------------------
  let [valuetoSearch, setvaluetoSearch] = useState("")
  let handleSearch = (e) => {
    setvaluetoSearch(e.target.value)
    setkeyWord(e.target.value)

  }

  // Search---------------------
  const initialObj = {
    "keyWord": keyWord,
    "pageNo": pageNo,
    "size": size
  }
  const dispatch = useDispatch();
  const selector = useSelector(state => state);
  const [products, setproducts] = useState([])

  const header = [
    "S.No",
    "MatchId",
    "Status",
    "",
    "",
    "",
    "",
    "Action",
  ]
  let [editId, seteditId] = useState("")
  let [editmodel, seteditmodel] = useState(false)
  let [deleteId, setdeleteId] = useState("")
  let [AddButton, setAddButton] = useState(false)
  let [tip, setTip] = useState(null)

  let handleDelete = (e) => {
    confirmAlert({


      title: 'Confirm to Delete ?',
      message: `Are you sure to Want to Delete Tip?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => dispatch(questionAction.deleteTip({ "id": e }, initialObj))
        },
        {
          label: 'No'
        }
      ]
    });


  }

  let upcomingMatchesData = (e) => {

    dispatch(questionAction.upcomingMatches())
    dispatch(questionAction.clearLiveMatch())

  }

  let liveMatchesData = (e) => {

    dispatch(questionAction.liveMatchList())
    dispatch(questionAction.upcomingLiveMatch())
  }

  let setAddButtonData = (e) => {
    console.log('e_________________', e);
    setTip(e?.name)
    setAddButton((prev) => !prev)
  }

  // ------------AddButton------------  /
  const [question, setQuestion] = useState('');
  const [correctOption, setCorrectOption] = useState('');
  const [error, setError] = useState({});
  const handleCloseModel = () => {

    setAddButton(false);
    setQuestion('');
    setCorrectOption('');
    setError('');
  };


  const handleSubmit = () => {
    if (handleValidation()) {

      let obj = {
        "message": question,
        "matchId": tip ? tip.toString() : null,
      }
      console.log(obj)
      dispatch(questionAction.createTip(obj, initialObj))
      handleCloseModel()
    }
  }
  const handleValidation = () => {
    let isValid = true;
    let error = {};


    if (!question.trim()) {
      error["tip"] = 'Message is required'

      isValid = false;
    }

    // if (!correctOption.trim() || isNaN(correctOption)) {
    //   error["matchId"] = 'MatchId is required'
    //   isValid = false;
    // }

    setError({ ...error });

    return isValid;
  };


  //disable-----------
  const handleDisable = (e) => {
    confirmAlert({
      title: 'Confirm to Disable ?',
      message: `Are you sure to Want to Disable Question?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => dispatch(questionAction.updateStatusQuestion({ "id": e.id }, initialObj))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  //disable-------
  //-----add model
  //--------Edit Model----------------
  const [formData, setFormData] = useState({
    id: '',
    question: '',
    points: 0
  });
  //submit Update
  const [errorupdate, setErrorsUpdate] = useState({})

  const handleUpdate = () => {

    const isValid = validateForm();
    if (isValid) {
      let obj = {
        id: formData.id,
        matchId: formData?.points.toString() || "",
        message: formData?.question,
      }

      dispatch(questionAction.updateTip(obj, initialObj))
      seteditmodel((prev) => !prev)
      seteditId("")
      setFormData({})
      setErrorsUpdate({})

    }
  };


  const validateForm = () => {
    const errors = {};

    if (!formData.question.trim()) {
      errors.question = "Message is required";
    }


    if (isNaN(formData.points) || formData.points <= 0) {
      errors.points = "Match Id must be a number";
    }


    setErrorsUpdate(errors);

    return Object.keys(errors).length === 0;
  };



  //--------Edit Model----------------


  // ------------
  let handlePageClick = (data) => {
    setCurrentPage(data.selected);
    setpageNo(data.selected + 1)

    let offset = Math.ceil(data.selected * size);
    setoffset(offset)

    let initialObj = {
      "keyWord": keyWord,
      "pageNo": data.selected + 1,
      "size": size
    }
    dispatch(questionAction.getTipList(initialObj))

  }

  let { getTipList, getTipTotal, loading, getRecentMatch, getLiveMatch } = selector.question && selector.question ? selector.question : []



  if (getRecentMatch && getRecentMatch.length > 0) {
    console.log('111111111111111111111', getRecentMatch);

    covertdata = getRecentMatch && getRecentMatch.length > 0 ? getRecentMatch.map((e) => ({
      id: e._id && e._id ? e._id : "",
      name: e.match_id && e.match_id ? e.match_id : "",
      color: e.match_status && e.match_status ? e.match_status : "",
      category: "",
      money: "",
      money2: "",
      money3: "",
      price: null,
      view: true,
      add: e.match_id && e.match_id ? e.match_id : "",
      fulldata: e || "",
    })) : []

  }

  if (getLiveMatch && getLiveMatch.length > 0) {
    console.log('111111111111111111111', getLiveMatch);

    covertdata = getLiveMatch && getLiveMatch.length > 0 ? getLiveMatch.map((e) => ({
      id: e._id && e._id ? e._id : "",
      name: e.match_id && e.match_id ? e.match_id : "",
      color: e.match_status && e.match_status ? e.match_status : "",
      category: "",
      money: "",
      money2: "",
      money3: "",
      price: null,
      view: true,
      add: e.match_id && e.match_id ? e.match_id : "",
      fulldata: e || "",
    })) : []

  }

  // if (getLiveMatch && getLiveMatch.length > 0) {
  //   console.log('111111111111111111111');

  //   covertdata = getLiveMatch && getLiveMatch.length > 0 ? getLiveMatch.map((e) => ({
  //     id: e._id && e._id ? e._id : "",
  //     name: e.message && e.message ? e.message : "",
  //     color: e.matchId && e.matchId ? e.matchId : "",
  //     category: "",
  //     money: "",
  //     money2: "",
  //     money3: "",
  //     price: null,
  //     fulldata: e || "",
  //   })) : []

  // }


  console.log("covertdatacovertdatacovertdatacovertdata", covertdata)

  //---------
  return (
    <>
      <Loader loading={loading} />
      <div className="z-0 overflow-hidden overflow-y-auto focus:outline-none">
        <div className="relative">
          <div className="relative z-20 flex min-h-screen">
            <div className="flex w-full overflow-hidden ">
              <div className="flex flex-col flex-1 overflow-hidden">
                <main className="relative flex-1 ">
                  <div className="p-3 2xl:p-10 sm:p-5">
                    <div className="mx-auto max-w-screen-3xl">
                      <sectiion className="flex justify-between my-4 ">
                        <div className=' flex justify-between items-center  w-full'>
                          <p className='text-2xl font-bold'>{QuestionJSON.Heading}</p>

                        </div>
                      </sectiion>
                      <div className='m-10 px-3 flex justify-between'>

                        <div>
                          <button onClick={() => liveMatchesData()} className='bg-[#475569] text-white px-4 py-2 rounded-xl'>Live Match</button>
                        </div>
                        <div>
                          <button onClick={() => upcomingMatchesData()} className='bg-[#475569] text-white px-4 py-2 rounded-xl'>Upcoming Matches</button>
                        </div>
                      </div>

                      {/* <Table tableJSON={DashboardJSON.tableJSON} /> */}
                      <Table header={header} offset={offset} products={covertdata} handleAdd={setAddButtonData} />
                    </div>
                    {

                      <nav className=" flex justify-end items-end">
                        {
                          getTipTotal && getTipTotal > 10 ?
                            <ReactPaginate
                              previousLabel={'Prev'}
                              nextLabel={'Next'}
                              breakLabel={'...'}
                              breakClassName={'break-me'}
                              pageCount={getTipTotal && getTipTotal / 10}
                              marginPagesDisplayed={1}
                              pageRangeDisplayed={1}
                              onPageChange={handlePageClick}
                              containerClassName={'pagination'}
                              pageClassName={'page-cls'}
                              activeClassName={'active'}
                              forcePage={currentPage}
                            />
                            : null}
                      </nav>
                    }

                  </div>
                </main>
              </div>
            </div>
          </div>
        </div>


      </div>
      <AddModel
        AddButton={AddButton}
        setAddButton={setAddButton}
        question={question}
        setQuestion={setQuestion}
        correctOption={correctOption}
        setCorrectOption={setCorrectOption}
        error={error}
        setError={setError}
        handleSubmit={handleSubmit}
        handleCloseModel={handleCloseModel}
      />
      <EditModel
        editId={editId}
        editmodel={editmodel}
        seteditmodel={seteditmodel}
        setFormData={setFormData}
        formData={formData}
        handleUpdate={handleUpdate}
        setErrorsUpdate={setErrorsUpdate}
        errorupdate={errorupdate}
      />

    </>
  );
};

export default Tip;



