import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import QuestionJSON from './Tip.json'
import ReactPaginate from 'react-paginate';
import Table from '../../components/Atoms/Table/Table';
import { useDispatch, useSelector } from 'react-redux';
import { questionAction, userActions } from '../../_actions';
import AddModel from './Components/AddModel';
import EditModel from './Components/EditModel';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { IoIosSearch } from "react-icons/io";
import Loader from '../../components/Loader/Loader';
const Tip = () => {
  const navigate = useNavigate();

  let covertdata = [];
  const [keyWord, setkeyWord] = useState("")
  const [pageNo, setpageNo] = useState(1)
  const [size, setsize] = useState(10)
  const [matchId, setmatchId] = useState('')
  let [Live,setLive] =useState(false)
  const [currentPage, setCurrentPage] = useState(0)
  const [offset, setoffset] = useState(0)
  // Search---------------------
  let [valuetoSearch, setvaluetoSearch] = useState("")
  let handleSearch = (e) => {
    setvaluetoSearch(e.target.value)
    setkeyWord(e.target.value)

  }

  // Search---------------------
  const initialObj = {
    "keyWord": keyWord,
    "pageNo": pageNo,
    "size": size
  }
  const dispatch = useDispatch();
  const selector = useSelector(state => state);
  const [products, setproducts] = useState([])

  const header = [
    "S.No",
    "MatchId",
    "Status",
    "",
    "",
    "",
    "",
    "Action",
  ]
  let [editId, seteditId] = useState("")
  let [editmodel, seteditmodel] = useState(false)
  let [deleteId, setdeleteId] = useState("")
  let [AddButton, setAddButton] = useState(false)
  let [tip, setTip] = useState(null)

  let handleDelete = (e) => {
    confirmAlert({


      title: 'Confirm to Delete ?',
      message: `Are you sure to Want to Delete Tip?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => dispatch(questionAction.deleteTip({ "id": e }, initialObj))
        },
        {
          label: 'No'
        }
      ]
    });


  }

  useEffect(()=>{
    dispatch(questionAction.upcomingMatches())
    dispatch(questionAction.clearLiveMatch())
  },[])
  let upcomingMatchesData = (e) => {
    setLive(false)
    dispatch(questionAction.upcomingMatches())
    dispatch(questionAction.clearLiveMatch())

  }

  let liveMatchesData = (e) => {
setLive(true)
    dispatch(questionAction.liveMatchList())
    dispatch(questionAction.upcomingLiveMatch())
  }

  let setAddButtonData = (e) => {
    console.log('e_________________', e);
    setTip(e?.name)
    setAddButton((prev) => !prev)
  }

  let viewData = () => {
    // props.history.push('/app/ViewTip')
    // location.pathname === "/app/Tip" ? "/app/Tip" : null
    navigate('/app/ViewTip');
    setTimeout(() => {
      // setNavbarOpen(!navbarOpen);
    }, 150);

  }

  // ------------AddButton------------  /
  const [question, setQuestion] = useState('');
  const [correctOption, setCorrectOption] = useState('');
  const [error, setError] = useState({});
  const handleCloseModel = () => {

    setAddButton(false);
    setQuestion('');
    setCorrectOption('');
    setError('');
  };


  const handleSubmit = (e) => {
    console.log(e)
    if (handleValidation()) {

      let obj = {
        "message": question,
        "matchId": matchId ? matchId.toString() : null,
      }
      console.log(obj)
      dispatch(questionAction.createTip(obj, initialObj))
      handleCloseModel()
    }
  }
  const handleValidation = () => {
    let isValid = true;
    let error = {};


    if (!question.trim()) {
      error["tip"] = 'Message is required'

      isValid = false;
    }

    // if (!correctOption.trim() || isNaN(correctOption)) {
    //   error["matchId"] = 'MatchId is required'
    //   isValid = false;
    // }

    setError({ ...error });

    return isValid;
  };


  //disable-----------
  const handleDisable = (e) => {
    confirmAlert({
      title: 'Confirm to Disable ?',
      message: `Are you sure to Want to Disable Question?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => dispatch(questionAction.updateStatusQuestion({ "id": e.id }, initialObj))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  //disable-------
  //-----add model
  //--------Edit Model----------------
  const [formData, setFormData] = useState({
    id: '',
    question: '',
    points: 0
  });
  //submit Update
  const [errorupdate, setErrorsUpdate] = useState({})

  const handleUpdate = () => {

    const isValid = validateForm();
    if (isValid) {
      let obj = {
        id: formData.id,
        matchId: formData?.points.toString() || "",
        message: formData?.question,
      }

      dispatch(questionAction.updateTip(obj, initialObj))
      seteditmodel((prev) => !prev)
      seteditId("")
      setFormData({})
      setErrorsUpdate({})

    }
  };


  const validateForm = () => {
    const errors = {};

    if (!formData.question.trim()) {
      errors.question = "Message is required";
    }


    if (isNaN(formData.points) || formData.points <= 0) {
      errors.points = "Match Id must be a number";
    }


    setErrorsUpdate(errors);

    return Object.keys(errors).length === 0;
  };



  //--------Edit Model----------------




  let { getTipList, getTipTotal, loading, getRecentMatch, getLiveMatch } = selector.question && selector.question ? selector.question : []



  if (getRecentMatch && getRecentMatch.length > 0) {


    covertdata = getRecentMatch && getRecentMatch.length > 0 ? getRecentMatch : []

  }

  if (getLiveMatch && getLiveMatch.length > 0) {
  
    covertdata = getLiveMatch && getLiveMatch.length > 0 ? getLiveMatch : []

  }



  console.log("covertdatacovertdatacovertdatacovertdata", covertdata)
// Pagination-----------
  // ------------
  let handlePageClick = (data) => {
    setCurrentPage(data.selected);
    setpageNo(data.selected + 1)

    let offset = Math.ceil(data.selected * size);
    setoffset(offset)

    let initialObj = {
      "keyWord": keyWord,
      "pageNo": data.selected + 1,
      "size": size
    }
    dispatch(questionAction.getTipList(initialObj))

  }

//-----------------------------------


let handleAddTip = (e) => {
  setmatchId(e?.match_id)
  setAddButton(true)
}

let handleViewtips=(e)=>{
  navigate(`/app/tips/${e?.match_id}`)
}

  return (
    <>
      <Loader loading={loading} />
      <div className="z-0 overflow-hidden overflow-y-auto focus:outline-none">
        <div className="relative">
          <div className="relative z-20 flex min-h-screen">
            <div className="flex w-full overflow-hidden ">
              <div className="flex flex-col flex-1 overflow-hidden">
                <main className="relative flex-1 ">
                  <div className="p-3 2xl:p-10 sm:p-5">
                    <div className="mx-auto max-w-screen-3xl">
                      <sectiion className="flex justify-between my-4 ">
                        <div className=' flex justify-between items-center  w-full'>
                          <p className='text-2xl font-bold'>{QuestionJSON.Heading}</p>
                        </div>
                      </sectiion>
                      <div className='m-10 px-3 flex justify-between'>

                        <div>
                          <button onClick={() => liveMatchesData()} className={`${Live?"bg-red-500":"bg-[#475569]"} text-white px-4 py-2 rounded-xl`}>Live Match</button>
                        </div>
                        <div>
                          <button onClick={() => upcomingMatchesData()} className={`${Live?"bg-[#475569]":"bg-red-500"} text-white px-4 py-2 rounded-xl`}>Upcoming Matches</button>
                        </div>
                      </div>

            

                      <div className="relative overflow-x-auto">
  <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
    <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
      <tr>
        <th scope="col" className="px-6 py-3">
        S.NO
        </th>
        <th scope="col" className="px-6 py-3">
        Series
        </th>
        <th scope="col" className="px-6 py-3">
         Time
        </th>
        <th scope="col" className="px-6 py-3">
        STATUS
        </th>
        <th scope="col" className="px-6 py-3">
         Type
        </th>
        <th scope="col" className="px-6 py-3">
        Team
        </th>
        <th scope="col" className="px-6 py-3">
     Action
        </th>
      </tr>
    </thead>
    <tbody>
    {covertdata&& covertdata.length>0&& covertdata.map((e,index)=>(

<tr className={index % 2 === 0 ? "bg-white border-b  " : "bg-gray-100  "}>
<th
  scope="row"
  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
>
{index + offset + 1}
</th>
<td className="px-6 py-4">{e?.series||""}</td>
<td className="px-6 py-4">{e?.match_date||""} ({e?.match_time||""})</td>
<td className="px-6 py-4">{e?.match_status||""}</td>
<td className="px-6 py-4">{e?.match_type||""}</td>
<td className="px-6 py-4">{e?.team_a_short||""} vs {e?.team_b_short||""}  </td>
<td className="px-6 py-4">
<div className=' flex gap-3'>
{/* <button onClick={()=>handleAddTip(e)} className=' bg-blue-600 px-3 py-2 rounded-md text-white ' >Add Tip</button> */}
<button onClick={()=>handleViewtips(e)} className=' bg-blue-600 px-3 py-2 rounded-md text-white '>View Tips</button>
</div>
</td>
</tr>
    ))
   
}
    </tbody>
  </table>
</div>

                    </div>
                    {

                      // <nav className=" flex justify-end items-end">
                      //   {
                      //     covertdata && covertdata.length > 10 ?
                      //       <ReactPaginate
                      //         previousLabel={'Prev'}
                      //         nextLabel={'Next'}
                      //         breakLabel={'...'}
                      //         breakClassName={'break-me'}
                      //         pageCount={covertdata && covertdata.length / 10}
                      //         marginPagesDisplayed={1}
                      //         pageRangeDisplayed={1}
                      //         onPageChange={handlePageClick}
                      //         containerClassName={'pagination'}
                      //         pageClassName={'page-cls'}
                      //         activeClassName={'active'}
                      //         forcePage={currentPage}
                      //       />
                      //       : null}
                      // </nav>
                    }

                  </div>
                </main>
              </div>
            </div>
          </div>
        </div>


      </div>
      <AddModel
        AddButton={AddButton}
        setAddButton={setAddButton}
        question={question}
        setQuestion={setQuestion}
        correctOption={correctOption}
        setCorrectOption={setCorrectOption}
        error={error}
        setError={setError}
        handleSubmit={handleSubmit}
        handleCloseModel={handleCloseModel}
      />
      <EditModel
        editId={editId}
        editmodel={editmodel}
        seteditmodel={seteditmodel}
        setFormData={setFormData}
        formData={formData}
        handleUpdate={handleUpdate}
        setErrorsUpdate={setErrorsUpdate}
        errorupdate={errorupdate}
      />

    </>
  );
};

export default Tip;



