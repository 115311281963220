import { userConstants } from '../_constants';
import { alertActions} from './alert.actions';
import { APIcallFunction, headerForPrivateAPI, headerForPublicAPI, logoutFunction } from '../_helpers';
export const userActions = {
    login,
    logout,
    getUserList,
    updateUserStatus,
    getUserQuestionList,
};
function login(data, navigate) {
    const credentials = {
        header : headerForPublicAPI(),
        method : "POST",
        body : data ?? {},
        endPoint : 'adminLogin'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {
                    window.sessionStorage.setItem("adminuser", JSON.stringify(user.data))
                    dispatch(success(user));
                    dispatch(alertActions.success("Login Successfully"))
                    if (navigate) {
                        navigate('/app/dashboard');
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}


function logout() {
    logoutFunction()
    return { type: userConstants.LOGOUT };
}

function updateUserStatus(data, pagination) {
    console.log(data, pagination)
    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: 'updateUserStatus'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(getUserList(pagination));
                    dispatch(alertActions.success("User Status Updated Successfully"))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: userConstants.UPDATE_USER_STATUS_REQUEST, user } }
    function success(user) { return { type: userConstants.UPDATE_USER_STATUS_SUCCESS, user } }
    function failure(error) { return { type: userConstants.UPDATE_USER_STATUS_FAILURE, error } }
}

function getUserQuestionList(data, pagination) {
    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: 'getUserQuestionList'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {
                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: userConstants.GET_USER_QUESTION_LIST_REQUEST, user } }
    function success(user) { return { type: userConstants.GET_USER_QUESTION_LIST_SUCCESS, user } }
    function failure(error) { return { type: userConstants.GET_USER_QUESTION_LIST_FAILURE, error } }
}



function getUserList(data, pagination) {
    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: 'getUserList'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {
                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: userConstants.GET_USER_LIST_REQUEST, user } }
    function success(user) { return { type: userConstants.GET_USER_LIST_SUCCESS, user } }
    function failure(error) { return { type: userConstants.GET_USER_LIST_FAILURE, error } }
}

