export const userConstants = {

    GET_USER_QUESTION_LIST_REQUEST:"GET_USER_QUESTION_LIST_REQUEST",
    GET_USER_QUESTION_LIST_SUCCESS:"GET_USER_QUESTION_LIST_SUCCESS",
    GET_USER_QUESTION_LIST_FAILURE:"GET_USER_QUESTION_LIST_FAILURE",






    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
    GET_USER_LIST_REQUEST:"GET_USER_LIST_REQUEST",
    GET_USER_LIST_SUCCESS:"GET_USER_LIST_SUCCESS",
    GET_USER_LIST_FAILURE:"GET_USER_LIST_FAILURE",

UPDATE_USER_STATUS_REQUEST:"UPDATE_USER_STATUS_REQUEST",
UPDATE_USER_STATUS_SUCCESS:"UPDATE_USER_STATUS_SUCCESS",
UPDATE_USER_STATUS_FAILURE:"UPDATE_USER_STATUS_FAILURE",
    LOGOUT: 'USERS_LOGOUT',

};
