import { combineReducers } from 'redux';
import usersReducer from './users.reducer';
import alertReducer from './alert.reducer';
import questionReducer from './question.reducer';

const rootReducer = combineReducers({
  users: usersReducer,
  alert: alertReducer,
  question: questionReducer
});

export default rootReducer;
