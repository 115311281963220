import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import QuestionJSON from './Tip.json'
import ReactPaginate from 'react-paginate';
import Table from '../../components/Atoms/Table/Table';
import { useDispatch, useSelector } from 'react-redux';
import { questionAction, userActions } from '../../_actions';
import { IoChevronBackCircle } from "react-icons/io5";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { IoIosSearch } from "react-icons/io";
import Loader from '../../components/Loader/Loader';
import AddModel from './Components/AddModel';
const TipByID = () => {
  const navigate = useNavigate();
  const params = useParams();

  let covertdata = [];
  const [keyWord, setkeyWord] = useState("")
  const [pageNo, setpageNo] = useState(1)
  const [size, setsize] = useState(10)
  const [currentPage, setCurrentPage] = useState(0)
  const [offset, setoffset] = useState(0)
  // Search---------------------
  let [valuetoSearch, setvaluetoSearch] = useState("")
  let handleSearch = (e) => {
    setvaluetoSearch(e.target.value)
    setkeyWord(e.target.value)

  }

  // Search---------------------
  const initialObj = {
    "keyWord": keyWord,
    "pageNo": pageNo,
    "size": size,
    matchId: params?.id
  }
  const dispatch = useDispatch();
  const selector = useSelector(state => state);
  const [products, setproducts] = useState([])

  const header = [
    "S.No",
    "MatchId",
    "Status",
    "",
    "",
    "",
    "",
    "Action",
  ]
  let [editId, seteditId] = useState("")
  let [editmodel, seteditmodel] = useState(false)
  let [deleteId, setdeleteId] = useState("")
  let [AddButton, setAddButton] = useState(false)
  let [tip, setTip] = useState(null)

  let handleDelete = (e) => {
    confirmAlert({


      title: 'Confirm to Delete ?',
      message: `Are you sure to Want to Delete Tip?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => dispatch(questionAction.deleteTip({ "id": e }, initialObj))
        },
        {
          label: 'No'
        }
      ]
    });


  }

  useEffect(() => {

    dispatch(questionAction.getMatchListById(initialObj))
  }, [params?.id])



  // ------------AddButton------------  /
  const [question, setQuestion] = useState('');
  const [correctOption, setCorrectOption] = useState('');
  const [error, setError] = useState({});
  const handleCloseModel = () => {

    setAddButton(false);
    setQuestion('');
    setCorrectOption('');
    setError('');
  };



  //disable-----------

  //disable-------
  //-----add model
  //--------Edit Model----------------
  const [formData, setFormData] = useState({
    id: '',
    question: '',
    points: 0
  });
  //submit Update
  const [errorupdate, setErrorsUpdate] = useState({})

  const handleUpdate = () => {

    const isValid = validateForm();
    if (isValid) {
      let obj = {
        id: formData.id,
        matchId: formData?.points.toString() || "",
        message: formData?.question,
      }

      dispatch(questionAction.updateTip(obj, initialObj))
      seteditmodel((prev) => !prev)
      seteditId("")
      setFormData({})
      setErrorsUpdate({})

    }
  };


  const validateForm = () => {
    const errors = {};

    if (!formData.question.trim()) {
      errors.question = "Message is required";
    }


    if (isNaN(formData.points) || formData.points <= 0) {
      errors.points = "Match Id must be a number";
    }


    setErrorsUpdate(errors);

    return Object.keys(errors).length === 0;
  };



  //--------Edit Model----------------




  let { loading, getmatchListbyIDTotal, getmatchListbyIDList } = selector.question && selector.question ? selector.question : []

  console.log(getmatchListbyIDList)





  // Pagination-----------
  // ------------
  let handlePageClick = (data) => {
    setCurrentPage(data.selected);
    setpageNo(data.selected + 1)

    let offset = Math.ceil(data.selected * size);
    setoffset(offset)

    let initialObj = {
      "keyWord": keyWord,
      "pageNo": data.selected + 1,
      "size": size,
      matchId: params?.id
    }


    dispatch(questionAction.getMatchListById(initialObj))

  }

  //-----------------------------------
  const handleSubmit = (e) => {
    console.log(e)
    if (handleValidation()) {

      let obj = {
        "message": question,
        "matchId":params?.id ,
      }
      console.log(obj)
      dispatch(questionAction.createTip(obj, initialObj))
      handleCloseModel()
    }
  }

  const handleValidation = () => {
    let isValid = true;
    let error = {};


    if (!question.trim()) {
      error["tip"] = 'Message is required'

      isValid = false;
    }

    setError({ ...error });

    return isValid;
  };


  let handleAddTip = (e) => {
    // setmatchId(e?.match_id)
    setAddButton(true)
  }

  let handleBack = () => {
    navigate(-1)
  }

  return (
    <>
      <Loader loading={loading} />
      <div className="z-0 overflow-hidden overflow-y-auto focus:outline-none">
        <div className="relative">
          <div className="relative z-20 flex min-h-screen">
            <div className="flex w-full overflow-hidden ">
              <div className="flex flex-col flex-1 overflow-hidden">
                <div className='  px-5'>
                  <IoChevronBackCircle onClick={() => handleBack()} className=' text-3xl cursor-pointer' />
                </div>
                <main className="relative flex-1 ">
                  <div className="p-3 2xl:p-10 sm:p-5">
                    <div className="mx-auto max-w-screen-3xl">
                      <sectiion className="flex justify-between my-4 ">
                        <div className=' flex justify-between items-center  w-full'>
                          <p className='text-2xl font-bold'>{QuestionJSON.Heading}</p>
                          {/* onClick={() => setAddButton((prev) => !prev)} */}
                          <button onClick={()=>handleAddTip()}   className='bg-[#475569] text-white px-4 py-2 rounded-xl'>Add</button>
                        </div>
                      </sectiion>

                      <div className="relative overflow-x-auto">
                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
                          <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                            <tr>
                              <th scope="col" className="px-6 py-3">
                                S.NO
                              </th>

                              <th scope="col" className="px-6 py-3">
                                MatchId
                              </th>
                              <th scope="col" className="px-6 py-3">
                                Tip
                              </th>


                            </tr>
                          </thead>
                          <tbody>
                            {getmatchListbyIDList && getmatchListbyIDList.length > 0 ?
                              getmatchListbyIDList.map((e, index) => (

                                <tr className={index % 2 === 0 ? "bg-white border-b  " : "bg-gray-100 border-b   "}>
                                  <th
                                    scope="row"
                                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                                  >
                                    {index + offset + 1}
                                  </th>

                                  <td className="px-6 py-4">{e?.matchId || ""}  </td>

                                  <td className="px-6 py-4">{e?.message || ""}  </td>

                                </tr>
                              ))
                              :
                              <tr>
                                <td>No data Found</td>
                              </tr>
                            }
                          </tbody>
                        </table>
                      </div>

                    </div>
                    {

                      <nav className=" flex justify-end items-end">
                        {
                          getmatchListbyIDList && getmatchListbyIDList.length > 10 ?
                            <ReactPaginate
                              previousLabel={'Prev'}
                              nextLabel={'Next'}
                              breakLabel={'...'}
                              breakClassName={'break-me'}
                              pageCount={getmatchListbyIDList && getmatchListbyIDList.length / 10}
                              marginPagesDisplayed={1}
                              pageRangeDisplayed={1}
                              onPageChange={handlePageClick}
                              containerClassName={'pagination'}
                              pageClassName={'page-cls'}
                              activeClassName={'active'}
                              forcePage={currentPage}
                            />
                            : null}
                      </nav>
                    }

                  </div>
                </main>
              </div>
            </div>
          </div>
        </div>


      </div>

      <AddModel
        AddButton={AddButton}
        setAddButton={setAddButton}
        question={question}
        setQuestion={setQuestion}
        correctOption={correctOption}
        setCorrectOption={setCorrectOption}
        error={error}
        setError={setError}
        handleSubmit={handleSubmit}
        handleCloseModel={handleCloseModel}
      />

{/* 
      <EditModel
        editId={editId}
        editmodel={editmodel}
        seteditmodel={seteditmodel}
        setFormData={setFormData}
        formData={formData}
        handleUpdate={handleUpdate}
        setErrorsUpdate={setErrorsUpdate}
        errorupdate={errorupdate}
      />  */}

    </>
  );
};

export default TipByID;



