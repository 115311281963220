import React, { useState, useEffect } from 'react';
import { AiFillCloseCircle } from "react-icons/ai";

export default function EditModel({ editmodel, editId, seteditmodel, formData, setFormData, handleUpdate, errorupdate,setErrorsUpdate }) {

  // Update formData with editId when editId changes
  useEffect(() => {
    if (editId) {
      setFormData({
        id: editId._id,
        question: editId.question,
        options: editId.options.map(option => ({ ...option })),
        points: editId.points,
        correctOption: editId.correctOption
      });
    }
  }, [editId]);

  const handleInputChange = (index, field, value) => {
    const updatedOptions = [...formData.options];
    updatedOptions[index][field] = value;
    setFormData({
      ...formData,
      options: updatedOptions
    });
  };

  let handleQuestionChange =(e)=>{
     setFormData({ ...formData, question: e.target.value })
     setErrorsUpdate({
      ...errorupdate,
      question: ""
    });
     
  }

  let handleOptionChangeone = (index, data, e) => {
    handleInputChange(index, data, e.target.value);
  
    // Reset the error for the specific option being edited
    setErrorsUpdate({
      ...errorupdate,
      [`optionName${index}`]: "", // Reset error for option name

    });
  };
  

  let handleOptionChangetwo = (index, data, e) => {
    handleInputChange(index, data, e.target.value);
  
    // Reset the error for the specific option being edited
    setErrorsUpdate({
      ...errorupdate,
  // Reset error for option name
      [`optionCid${index}`]: ""   // Reset error for option cid
    });
  };

  const handleCorrectOptionChange = (e) => {
    const value = e.target.value;
    setFormData(prevFormData => ({
      ...prevFormData,
      correctOption: value
    }));
    setErrorsUpdate({...errorupdate,correctOption:''})
  };
  

  const handlePointsChange = (e) => {
    const value = parseInt(e.target.value);
    setFormData(prevFormData => ({
      ...prevFormData,
      points: isNaN(value) ? '' : value
    }));
    
    setErrorsUpdate({...errorupdate,points:''})
  };
  


  return (
    <div className={`${editmodel ? 'block' : 'hidden'}`}>
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">

        <div className="bg-white p-4 rounded-lg">
          <div className=' flex justify-between items-start'>

            <h2 className="text-lg font-semibold mb-4">Edit Question</h2>
            <AiFillCloseCircle className=' text-xl cursor-pointer' onClick={() => seteditmodel(false)} />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">Question:</label>
            <input
              type="text"
              value={formData.question}
              onChange={(e)=>handleQuestionChange(e)}
              className={`border ${errorupdate.question ? 'border-red-500' : 'border-gray-300'} rounded px-3 py-2 mt-1 w-full`}
            />
            {errorupdate.question && <p className="text-red-500 text-sm">{errorupdate.question}</p>}
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">Options:</label>
            {formData?.options?.map((option, index) => (
              <div key={index} className="flex items-center">
                <input
                  type="text"
                  value={option.name}
                  placeholder='Option Name'
                  onChange={(e)=>handleOptionChangeone(index, 'name', e)}
                  className={`border ${errorupdate[`optionName${index}`] ? 'border-red-500' : 'border-gray-300'} rounded px-3 py-2 mt-1 mr-2 w-full`}
                />
                {errorupdate[`optionName${index}`] && <p className="text-red-500 text-sm"></p>}
                <input
                  type="number"
                  placeholder='Option id'
                  value={option.cid}
                  onChange={(e)=>handleOptionChangetwo(index, 'cid', e)}
                  className={`border ${errorupdate[`optionCid${index}`] ? 'border-red-500' : 'border-gray-300'} rounded px-3 py-2 mt-1 w-full`}
                />
                {errorupdate[`optionCid${index}`] && <p className="text-red-500 text-sm"></p>}
              </div>
            ))}
          </div>

          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">Correct Option:</label>
            <input
              type="number"
              value={formData.correctOption}
              onChange={(e) => handleCorrectOptionChange(e)}
              className={`border ${errorupdate.correctOption ? 'border-red-500' : 'border-gray-300'} rounded px-3 py-2 mt-1 w-full`}
            />
            {errorupdate.correctOption && <p className="text-red-500 text-sm">{errorupdate.correctOption}</p>}
          </div>

          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">Points:</label>
            <input
              type="number"
              value={formData.points}
              onChange={(e) => handlePointsChange(e)}
              className={`border ${errorupdate.points ? 'border-red-500' : 'border-gray-300'} rounded px-3 py-2 mt-1 w-full`}
            />
            {errorupdate.points && <p className="text-red-500 text-sm">{errorupdate.points}</p>}
          </div>
          <button onClick={handleUpdate} className="bg-blue-500 w-full text-white px-4 py-2 rounded">Update</button>
        </div>
      </div>
    </div>
  );
}
