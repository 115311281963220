import {
  QuestionConstants,
} from '../_constants';





export default function question(state = {}, action) {
  console.log('action___________________', action);
  switch (action.type) {

    
    
    


    case QuestionConstants.GET_MATCH_LIST_BY_ID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case QuestionConstants.GET_MATCH_LIST_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        getmatchListbyIDTotal:action?.user?.data?.total,
        getmatchListbyIDList:action?.user?.data?.list,

      };
    case QuestionConstants.GET_MATCH_LIST_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case QuestionConstants.DELET_TIP_REQUEST:
      return {
        ...state,
        loading: true
      };
    case QuestionConstants.DELET_TIP_SUCCESS:
      return {
        ...state,
        loading: false,

      };
    case QuestionConstants.DELET_TIP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case QuestionConstants.GET_TIP_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case QuestionConstants.GET_TIP_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getTipList: action?.user?.data?.list,
        getTipTotal: action?.user?.data?.total
      };
    case QuestionConstants.GET_TIP_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case QuestionConstants.SUCCESS_CLEAR_LIVE_MATCH:
      return {
        ...state,
        loading: false,
        getLiveMatch: null
      };

    case QuestionConstants.SUCCESS_CLEAR_UPCOMMING_MATCH:
      return {
        ...state,
        loading: false,
        getRecentMatch: null
      };


    case QuestionConstants.GET_UPCOMING_MATCH_REQUEST:
      return {
        ...state,
        loading: true
      };
    case QuestionConstants.GET_UPCOMING_MATCH_SUCCESS:
      return {
        ...state,
        loading: false,
        getRecentMatch: action?.user?.data
        // getTipTotal: action?.user?.data?.total
      };
    case QuestionConstants.GET_UPCOMING_MATCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case QuestionConstants.GET_LIVE_MATCH_REQUEST:
      return {
        ...state,
        loading: true
      };
    case QuestionConstants.GET_LIVE_MATCH_SUCCESS:
      return {
        ...state,
        loading: false,
        getLiveMatch: action?.user?.data
        // getTipTotal: action?.user?.data?.total
      };
    case QuestionConstants.GET_LIVE_MATCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case QuestionConstants.UPDATE_QUESTION_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case QuestionConstants.UPDATE_QUESTION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case QuestionConstants.UPDATE_QUESTION_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case QuestionConstants.CREATE_QUESTION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case QuestionConstants.CREATE_QUESTION_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case QuestionConstants.CREATE_QUESTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case QuestionConstants.GET_QUESTION_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case QuestionConstants.GET_QUESTION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getQuestionList: action?.user?.data?.list,
        getQuestionTotal: action?.user?.data?.total
      };
    case QuestionConstants.GET_QUESTION_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return state
  }
}