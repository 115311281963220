import React, { useEffect, useState } from 'react';
import QuestionJSON from './Question.json'
import Table from '../../components/Atoms/Table/Table';
import { useDispatch, useSelector } from 'react-redux';
import { questionAction, userActions } from '../../_actions';
import ReactPaginate from 'react-paginate';
import AddModel from './Components/AddModel';
import EditModel from './Components/EditModel';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { IoIosSearch } from "react-icons/io";
import Loader from '../../components/Loader/Loader';
const Question = () => {
  const [keyWord, setkeyWord] = useState("")
  const [pageNo, setpageNo] = useState(1)
  const [size, setsize] = useState(10)
  const [offset, setoffset] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const initialObj = {
    "keyWord": keyWord,
    "pageNo": pageNo,
    "size": size
  }
  const dispatch = useDispatch();
  const selector = useSelector(state => state);
  const [products, setproducts] = useState([])


  // Search---------------------
  let [valuetoSearch, setvaluetoSearch] = useState("")
  let handleSearch = (e) => {
    setvaluetoSearch(e.target.value)
    setkeyWord(e.target.value)

  }

  // Search---------------------

  useEffect(() => {
    dispatch(questionAction.getQuestionList(initialObj))

  }, [valuetoSearch])

  useEffect(() => {
    if (selector.question && selector.question.getQuestionList) {
      setproducts(
        selector.question.getQuestionList.map((e) => ({
          id: e._id && e._id ? e._id : "",
          name: e.question && e.question ? e.question : "",
          color: e.points && e.points ? e.points : "",
          category: "",
          money: "",
          money2: "",
          money3: "",
          price:  e.isDisabled=== true ?true :false,
          fulldata: e || "",
        }))
      );
    }
  }, [selector.question]);
  const header = [
    "S.No",
    "Question",
    "Points",
    "",
    "",
    "",
    "",
    "Action",
  ]
  let [editId, seteditId] = useState("")
  let [editmodel, seteditmodel] = useState(false)
  let [deleteId, setdeleteId] = useState("")
  let [AddButton, setAddButton] = useState(false)

  let handleDelete = (e) => {
    confirmAlert({


      title: 'Confirm to Delete ?',
      message: `Are you sure to Want to Delete Question?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => dispatch(questionAction.deleteQuestion({ "id": e }, initialObj))
        },
        {
          label: 'No'
        }
      ]
    });


  }

  let handleEdit = (e) => {

    seteditId(e?.fulldata)
    seteditmodel((prev) => !prev)
  }

  // ------------AddButton------------  /
  const [question, setQuestion] = useState('');
  const [correctOption, setCorrectOption] = useState('');
  const [newOptions, setNewOptions] = useState([{ name: '', cid: '' }]);
  const [error, setError] = useState('');
  const [points, setPoints] = useState('');
  const handleCloseModel = () => {
    setAddButton((prev) => !prev);
    setQuestion('');
    setCorrectOption('');
    setNewOptions([{ name: '', cid: '' }]);
    setPoints("")
    setError('');
  };


  const handleSubmit = () => {
    if (handleValidSubmit()) {

      let obj = {
        "question": question,
        "options": newOptions,
        "correctOption": correctOption,
        "points": points,
      }

      dispatch(questionAction.createQuestion(obj, initialObj))
      handleCloseModel()
    }
  }
  const handleValidSubmit = () => {
    let form = true;
    // Check if question is empty

    if (question.trim() === '') {
      setError('Please enter a question.');
      form = false;
      return;
    }
    if (correctOption.trim() == "") {
      form = false;
      setError('Please enter a Correct Option Cid.');
      return;
    }
    if (points.trim() == "") {
      form = false;
      setError('Please enter a points.');
      return;
    }


    // Check if any option field is empty
    for (let i = 0; i < newOptions.length; i++) {
      if (newOptions[i].name.trim() === '' || newOptions[i].cid.trim() === '') {
        form = false;
        setError('Option fields cannot be empty.');
        return;
      }
    }

    // Check if Cid values are unique
    const cidSet = new Set();
    for (let i = 0; i < newOptions.length; i++) {
      if (cidSet.has(newOptions[i].cid)) {
        form = false;
        setError('Cid values must be unique.');
        return;
      }
      cidSet.add(newOptions[i].cid);
    }

    // Clear error message
    setError('');
    return form;
  };

  const handlePoints = (e) => {
    setPoints(e.target.value)
  }

  //disable-----------
  const handleDisable = (e) => {
    confirmAlert({


      title: 'Confirm to Disable ?',
      message: `Are you sure to Want to Disable Question?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => dispatch(questionAction.updateStatusQuestion({ "id": e.id }, initialObj))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  //disable-------
  //-----add model
  //--------Edit Model----------------
  const [formData, setFormData] = useState({
    id: '',
    question: '',
    options: [],
    points: 0
  });
  //submit Update
  const [errorupdate, setErrorsUpdate] = useState({})

  const handleUpdate = () => {
    const isValid = validateForm();
    if (isValid) {
      let obj = {
        id: formData.id,
        correctOption: formData?.correctOption,
        options: formData?.options && formData?.options && formData?.options.map((e) => ({ name: e.name, cid: e.cid})),
        points: formData?.points,
        question: formData?.question,
      }

      dispatch(questionAction.updateQuestion(obj, initialObj))
      seteditmodel((prev)=>!prev)
      seteditId("")
      setFormData({})
      setErrorsUpdate({})
    }
  };


  const validateForm = () => {
    const errors = {};

    if (!formData.question.trim()) {
      errors.question = "Question is required";
    }

    formData.options.forEach((option, index) => {
      if (!option.name.trim()) {
        errors[`optionName${index}`] = "Option name is required";
      }
      if (!option.cid.trim()) {
        errors[`optionCid${index}`] = "Option cid is required";
      }
    });

    if (isNaN(formData.points) || formData.points <= 0) {
      errors.points = "Points must be a positive number";
    }

    if (isNaN(formData.correctOption) || formData.correctOption <= 0) {
      errors.correctOption = "Correct option must be a positive number";
    }

    setErrorsUpdate(errors);

    return Object.keys(errors).length === 0;
  };

  //---------pagination---------
  let handlePageClick = (data) => {
    setCurrentPage(data.selected);
    setpageNo(data.selected + 1)

    let offset = Math.ceil(data.selected * size);
    setoffset(offset)
    console.log(pageNo)
    let initialObj = {
      "keyWord": keyWord,
      "pageNo": data.selected + 1,
      "size": size
    }
    dispatch(questionAction.getQuestionList(initialObj))

  }

  let { getQuestionTotal, loading } = selector?.question || {};


  //--------Edit Model----------------
  return (
    <>
      <Loader loading={loading} />
      <div className="z-0 overflow-hidden overflow-y-auto focus:outline-none">
        <div className="relative">
          <div className="relative z-20 flex min-h-screen">
            <div className="flex w-full overflow-hidden ">
              <div className="flex flex-col flex-1 overflow-hidden">
                <main className="relative flex-1 ">
                  <div className="p-3 2xl:p-10 sm:p-5">
                    <div className="mx-auto max-w-screen-3xl">
                      <sectiion className="flex justify-between my-4 ">
                        <div className=' flex justify-between items-center  w-full'>
                          <p className='text-2xl font-bold'>{QuestionJSON.Heading}</p>
                          <div className=' flex gap-2 relative'>
                            <IoIosSearch className=' absolute top-3 left-2' />
                            <input value={valuetoSearch} onChange={handleSearch} type='text' className=' outline-none rounded-full border px-5 pl-7 ' placeholder=' Search' />
                            <button onClick={() => setAddButton((prev) => !prev)} className='bg-[#475569] text-white px-4 py-2 rounded-xl'>Add</button>
                          </div>
                        </div>
                      </sectiion>
                      {/* <Table tableJSON={DashboardJSON.tableJSON} /> */}
                      <Table offset={offset} header={header} products={products} handleDisable={handleDisable} setdeleteId={setdeleteId} handleEdit={handleEdit} seteditId={seteditId} handleDelete={handleDelete} />
                    </div>
                    {

                      <nav className=" flex justify-end items-end">
                        {
                          getQuestionTotal && getQuestionTotal > 10 ?
                            <ReactPaginate
                              previousLabel={'Prev'}
                              nextLabel={'Next'}
                              breakLabel={'...'}
                              breakClassName={'break-me'}
                              pageCount={getQuestionTotal && getQuestionTotal / 10}
                              marginPagesDisplayed={1}
                              pageRangeDisplayed={1}
                              onPageChange={handlePageClick}
                              containerClassName={'pagination'}
                              pageClassName={'page-cls'}
                              activeClassName={'active'}
                              forcePage={currentPage}
                            />
                            : null}
                      </nav>
                    }
                  </div>
                </main>
              </div>
            </div>
          </div>
        </div>
        <AddModel
          AddButton={AddButton}
          setAddButton={setAddButton}
          question={question}
          setQuestion={setQuestion}
          correctOption={correctOption}
          newOptions={newOptions}
          setNewOptions={setNewOptions}
          setCorrectOption={setCorrectOption}
          error={error}
          setError={setError}
          handleSubmit={handleSubmit}
          points={points}
          handleCloseModel={handleCloseModel}
          setPoints={setPoints}
          handlePoints={handlePoints}
        />
        <EditModel
          editId={editId}
          editmodel={editmodel}
          seteditmodel={seteditmodel}
          setFormData={setFormData}
          formData={formData}
          handleUpdate={handleUpdate}
          setErrorsUpdate={setErrorsUpdate}
          errorupdate={errorupdate}
        />
      </div>

    </>
  );
};

export default Question;
