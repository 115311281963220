import React from 'react';
import { AiFillCloseCircle } from "react-icons/ai";

export default function AddModel({ handleCloseModel, AddButton, question, setQuestion, correctOption, setCorrectOption, error, setError, handleSubmit }) {

  let handleQuestion = (e) => {
    setQuestion(e.target.value);
    setError({ ...error, tip: "" });
  };

  let handleCorrectionOption = (e) => {
    setCorrectOption(e.target.value);
    setError({ ...error, matchId: "" });
  };



  return (
    <div className={`${AddButton ? 'block' : 'hidden'} fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50`}>
      <div className="bg-white p-4 rounded-lg relative">
        <div className='flex justify-between relative items-start'>
          <h2 className="text-lg font-semibold mb-4">Add Tip</h2>
          <AiFillCloseCircle className='text-xl cursor-pointer' onClick={handleCloseModel} />
        </div>
        <div className="flex flex-col items-center justify-center mb-4 w-[30vw] ">
          <div className='w-full p-1'>
            <input
              type="text"
              value={question || ""}
              onChange={(e) => handleQuestion(e)}
              placeholder="Enter Tip"
              className={`border ${error["tip"] ? 'border-red-500' : 'border-gray-300'} rounded px-2 py-1 w-full`}
            />
            {error && <p className="text-red-500">{error?.tip || ""}</p>}
          </div>

          {/* <div className='w-full p-1'>
            <input
              type="number"
              value={correctOption || ""}
              onChange={(e) => handleCorrectionOption(e)}
              placeholder="Enter matchId"
              className={`border ${error["matchId"] ? 'border-red-500' : 'border-gray-300'} rounded px-2 py-1 w-full`}
            />
            {error && <p className="text-red-500">{error?.matchId || ""}</p>}

          </div> */}

          {/* {error && <p className="text-red-500">{error}</p>} */}
          <button onClick={handleSubmit} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4">Submit</button>
        </div>
      </div>
    </div>
  );
}



