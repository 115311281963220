import React from 'react';
import { AiFillCloseCircle } from "react-icons/ai";
import { IoAddCircle } from "react-icons/io5";
import { useDispatch } from 'react-redux';


export default function AddModel({points,handlePoints,handleCloseModel, AddButton,question,setQuestion ,correctOption,setCorrectOption,newOptions,setNewOptions,error,setError,handleSubmit}) {



  const handleRemoveOption = (index) => {
    if (newOptions.length > 1) {
      const updatedOptions = [...newOptions];
      updatedOptions.splice(index, 1);
      setNewOptions(updatedOptions);
    }
  };

  const handleNewOptionChange = (index, field, value) => {
    const updatedOptions = [...newOptions];
    updatedOptions[index][field] = value;
    setNewOptions(updatedOptions);
  };

  const handleAddNewOptionField = () => {
    const nonRemovableFields = newOptions.slice(0, newOptions.length - 1);
    const removableField = newOptions[newOptions.length - 1];
    setNewOptions([...nonRemovableFields, removableField, { name: '', cid: '' }]);
  };













 
  

let handleQuestion=(e)=>{
    setQuestion(e.target.value)
    setError('');
}
let handleCorrectionOption=(e)=>{
    setCorrectOption(e.target.value)
    setError('');
}
let handleOption = (index, field, e) => {
    handleNewOptionChange(index, field, e.target.value);
    setError('');
  };
  


  return (
    <div className={`${AddButton ? 'block' : 'hidden'} fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50`}>
      <div className="bg-white p-4 rounded-lg relative">
        {error && <div className=' bg-red-600 absolute w-full  -top-2 left-0  flex text-center justify-center text-white'>{error}</div>}
        <div className='flex justify-between relative items-start'>
          <h2 className="text-lg font-semibold mb-4">Add Question</h2>
          <AiFillCloseCircle className='text-xl text-[#475569] cursor-pointer' onClick={handleCloseModel} />
        </div>
        <div className="flex flex-col items-center justify-center mb-4 w-full">
          <div className='w-full p-1'>
            <input
              type="text"
              value={question}
              onChange={(e) => handleQuestion(e)}
              placeholder="Enter Question"
              className="border border-gray-300 rounded px-2 py-1 w-full"
            />
          </div>
          {newOptions.map((option, index) => (
            <div key={index} className='flex w-full p-1 relative'>
              <input
                type="text"
                value={option.name}
                onChange={(e)=>handleOption(index, 'name', e)}
                placeholder="Enter Option"
                className="border border-gray-300 rounded px-2 py-1 w-full "
              />
              <input
                type="number"
                value={option.cid}
                onChange={(e)=>handleOption(index, 'cid', e)}
                placeholder="Enter cid"
                className="border border-gray-300 rounded px-2 py-1 w-full "
              />
              {index !== newOptions.length - 1 ? (
                <AiFillCloseCircle title='Remove Option' onClick={() => handleRemoveOption(index)} className='text-xl text-[#475569] cursor-pointer absolute -right-1 top-0' />
              ) : (
                <IoAddCircle title=' Add more Options' onClick={handleAddNewOptionField} className=' text-xl text-blue-700 cursor-pointer absolute -right-1 top-0' />
              )}
            </div>
          ))}
          <div className='w-full p-1'>
            <input
              type="number"
              value={correctOption}
              onChange={(e) => handleCorrectionOption(e)}
              placeholder="Enter Correct Option Cid"
              className="border border-gray-300 rounded px-2 py-1 w-full"
            />
          </div>
          <div className='w-full p-1'>
            <input
              type="number"
              value={points}
              onChange={(e)=>handlePoints(e)}
              placeholder="Point for Question"
              className="border border-gray-300 rounded px-2 py-1 w-full"
            />
          </div>
          {/* {error && <p className="text-red-500">{error}</p>} */}
          <button onClick={handleSubmit} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4">Submit</button>
        </div>
      </div>
    </div>
  );
}
