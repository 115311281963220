import { QuestionConstants, userConstants } from '../_constants';
import { alertActions } from './alert.actions';
import { APIcallFunction, headerForPrivateAPI, logoutFunction, unHeaderForPrivateAPI, APIcallFunctionMatch } from '../_helpers';
import { userActions } from './user.actions';

export const questionAction = {
    getQuestionList,
    createQuestion,
    deleteQuestion,
    updateQuestion,
    updateStatusQuestion,



    getTipList,
    createTip,
    updateTip,
    deleteTip,
    upcomingMatches,
    liveMatchList,
    clearLiveMatch,
    upcomingLiveMatch,




    getMatchListById
};
function getMatchListById(data, pagination) {
    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: 'getMatchListById'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {
                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: QuestionConstants.GET_MATCH_LIST_BY_ID_REQUEST, user } }
    function success(user) { return { type: QuestionConstants.GET_MATCH_LIST_BY_ID_SUCCESS, user } }
    function failure(error) { return { type: QuestionConstants.GET_MATCH_LIST_BY_ID_FAILURE, error } }
}





function deleteTip(data, pagination) {
    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: 'deleteTip'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(questionAction.getTipList(pagination))
                    dispatch(alertActions.success("Tip Delete"))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: QuestionConstants.DELET_TIP_REQUEST, user } }
    function success(user) { return { type: QuestionConstants.DELET_TIP_SUCCESS, user } }
    function failure(error) { return { type: QuestionConstants.DELET_TIP_FAILURE, error } }
}

function clearLiveMatch() {
    return (dispatch) => {
        dispatch(success());
    };
    function success(user) { return { type: QuestionConstants.SUCCESS_CLEAR_LIVE_MATCH, user } }
}

function upcomingLiveMatch() {
    return (dispatch) => {
        dispatch(success());
    };
    function success(user) { return { type: QuestionConstants.SUCCESS_CLEAR_UPCOMMING_MATCH, user } }
}

function getTipList(data, pagination) {
    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: 'getTipList'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {
                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: QuestionConstants.GET_TIP_LIST_REQUEST, user } }
    function success(user) { return { type: QuestionConstants.GET_TIP_LIST_SUCCESS, user } }
    function failure(error) { return { type: QuestionConstants.GET_TIP_LIST_FAILURE, error } }
}

function upcomingMatches() {
    const credentials = {
        header: unHeaderForPrivateAPI(),
        method: "GET",
        // body: data ?? {},
        endPoint: 'upcomingMatches'
    }
    return dispatch => {
        dispatch(request());
        APIcallFunctionMatch(credentials)
            .then(
                user => {
                    console.log('user___________________', user);
                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: QuestionConstants.GET_UPCOMING_MATCH_REQUEST, user } }
    function success(user) { return { type: QuestionConstants.GET_UPCOMING_MATCH_SUCCESS, user } }
    function failure(error) { return { type: QuestionConstants.GET_UPCOMING_MATCH_FAILURE, error } }
}

function liveMatchList() {
    const credentials = {
        header: unHeaderForPrivateAPI(),
        method: "GET",
        // body: data ?? {},
        endPoint: 'liveMatchList'
    }
    return dispatch => {
        dispatch(request());
        APIcallFunctionMatch(credentials)
            .then(
                user => {
                    console.log('user___________________', user);
                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: QuestionConstants.GET_LIVE_MATCH_REQUEST, user } }
    function success(user) { return { type: QuestionConstants.GET_LIVE_MATCH_SUCCESS, user } }
    function failure(error) { return { type: QuestionConstants.GET_LIVE_MATCH_FAILURE, error } }
}
function createTip(data, pagination) {
    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: 'createTip'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(alertActions.success("Tip Created"))
                    dispatch(questionAction.getMatchListById(pagination))
                    // dispatch(questionAction.getTipList(pagination))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: QuestionConstants.CREATE_TIP_REQUEST, user } }
    function success(user) { return { type: QuestionConstants.CREATE_TIP_SUCCESS, user } }
    function failure(error) { return { type: QuestionConstants.CREATE_TIP_FAILURE, error } }
}
function updateTip(data, pagination) {
    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: 'updateTip'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(alertActions.success("Tip Updated"))
                    dispatch(questionAction.getTipList(pagination))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: QuestionConstants.CREATE_TIP_REQUEST, user } }
    function success(user) { return { type: QuestionConstants.CREATE_TIP_SUCCESS, user } }
    function failure(error) { return { type: QuestionConstants.CREATE_TIP_FAILURE, error } }
}

function updateStatusQuestion(data, pagination) {
    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: 'updateStatusQuestion'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(questionAction.getQuestionList(pagination))

                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: QuestionConstants.UPDATE_QUESTION_LIST_REQUEST, user } }
    function success(user) { return { type: QuestionConstants.UPDATE_QUESTION_LIST_SUCCESS, user } }
    function failure(error) { return { type: QuestionConstants.UPDATE_QUESTION_LIST_FAILURE, error } }
}

function updateQuestion(data, pagination) {
    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: 'updateQuestion'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(questionAction.getQuestionList(pagination))
                    dispatch(alertActions.success("Question Updated"))

                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: QuestionConstants.UPDATE_QUESTION_LIST_REQUEST, user } }
    function success(user) { return { type: QuestionConstants.UPDATE_QUESTION_LIST_SUCCESS, user } }
    function failure(error) { return { type: QuestionConstants.UPDATE_QUESTION_LIST_FAILURE, error } }
}

function getQuestionList(data, navigate) {
    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: 'getQuestionList'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {
                    console.log('user______________________', user);
                    dispatch(success(user));

                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: QuestionConstants.GET_QUESTION_LIST_REQUEST, user } }
    function success(user) { return { type: QuestionConstants.GET_QUESTION_LIST_SUCCESS, user } }
    function failure(error) { return { type: QuestionConstants.GET_QUESTION_LIST_FAILURE, error } }
}
function createQuestion(data, pagination) {
    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: 'createQuestion'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(questionAction.getQuestionList(pagination))
                    dispatch(alertActions.success("Question Created"))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: QuestionConstants.CREATE_QUESTION_REQUEST, user } }
    function success(user) { return { type: QuestionConstants.CREATE_QUESTION_SUCCESS, user } }
    function failure(error) { return { type: QuestionConstants.CREATE_QUESTION_FAILURE, error } }
}



function deleteQuestion(data, pagination) {
    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: 'deleteQuestion'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(questionAction.getQuestionList(pagination))
                    dispatch(alertActions.success("Question Delete"))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: QuestionConstants.CREATE_QUESTION_REQUEST, user } }
    function success(user) { return { type: QuestionConstants.CREATE_QUESTION_SUCCESS, user } }
    function failure(error) { return { type: QuestionConstants.CREATE_QUESTION_FAILURE, error } }
}