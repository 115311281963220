import React from 'react'
import { MdOutlineDelete } from "react-icons/md";
import { RiEditLine } from "react-icons/ri";
import { IoIosAddCircleOutline } from "react-icons/io";
import { SiTeamviewer } from "react-icons/si";
export default function Table({ products, handleDelete, setdeleteId, handleDisable, header, handleEdit, offset, handleAdd, handleView }) {

  console.log(products[0]?.price)

  return (
    <div>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
          <caption className="p-5 text-lg font-semibold text-left rtl:text-right text-gray-900 bg-white  ">
            {/* Our products */}
            <p className="mt-1 text-sm font-normal text-gray-500 "></p>
          </caption>
          <thead className="text-xs text-gray-700 uppercase bg-gray-50  ">
            <tr>
              {header && header.map((e) =>

              (<th scope="col" className="px-6 py-3 ">
                {e}
              </th>
              )
              )}
            </tr>
          </thead>
          <tbody>
            {products.map((product, index) => (
              <tr key={index} className={index % 2 === 0 ? "bg-white border-b  " : "bg-gray-100  "}>
                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                  {index + offset + 1 || ""}
                </th>
                <td className="px-6 py-4">
                  {product.name || ""}
                </td>
                <td className="px-6 py-4">
                  {product.color || ""}
                </td>
                <td className="px-6 py-4">
                  {product.category}
                </td>
                <td className="px-6 py-4">
                  {product.money || ""}
                </td>
                <td className="px-6 py-4">
                  {product.money2 || ""}
                </td>


                <td className="px-6 py-4">
                  {product.money3 || ""}
                </td>


                <td className="px-6 py-4 text-right flex justify-end gap-3 items-end">
                  {product.money3 || ""}
                </td>
                <td className="px-6 py-4 text-right justify-start flex gap-4">

                  {product.price ? <label onClick={() => handleDisable(product)}  class="flex items-center cursor-pointer targetablepx-4 tooltip">
                  


                    <div class="relative" title='Disabled' >
                        <div class="red  shadow-md border border-gray-300  w-10 h-6 rounded-full"></div>
                        <div class="dot absolute right-1 top-1 bg-blue-400 w-4 h-4 rounded-full transition"></div>
                      </div>
                  </label>
                    :
                    <label onClick={() => handleDisable(product)} class="flex items-center cursor-pointer targetablepx-4 tooltip">
                        <div onClick={() => handleDisable(product)} class="relative" title='Disabled' >
                      <div class="red  shadow-md border border-gray-300  w-10 h-6 rounded-full"></div>
                      <div class="dot absolute left-1 top-1 bg-red-400 w-4 h-4 rounded-full transition"></div>
                    </div>
                    </label>
                    }

                  {product.view != null ? product.view ?
                    <SiTeamviewer title="View" onClick={() => handleView(product)} className=" text-2xl cursor-pointer font-medium text-blue-600 dark:text-blue-500 hover:underline" />
                    :
                    <SiTeamviewer title="View" onClick={() => handleView(product)} className=" text-2xl cursor-pointer font-medium text-blue-600 dark:text-blue-500 hover:underline" />
                    :
                    null}

                  {product.add != null ? product.add ?
                    <IoIosAddCircleOutline title="Add" onClick={() => handleAdd(product)} className=" text-2xl cursor-pointer font-medium text-blue-600 dark:text-blue-500 hover:underline" />
                    :
                    <IoIosAddCircleOutline title="Add" onClick={() => handleAdd(product)} className=" text-2xl cursor-pointer font-medium text-blue-600 dark:text-blue-500 hover:underline" />
                    :
                    null}


                  {product.price != null ? product.price ?
                    <RiEditLine title="Edit" onClick={() => handleEdit(product)} className=" text-2xl cursor-pointer font-medium text-blue-600 dark:text-blue-500 hover:underline" />
                    :
                    <RiEditLine title="Edit" onClick={() => handleEdit(product)} className=" text-2xl cursor-pointer font-medium text-blue-600 dark:text-blue-500 hover:underline" />
                    :
                    null}


                  {product.price != null ? product.price ?
                    <div title="Delete" onClick={() => handleDelete(product?.id)} className=" cursor-pointer font-medium text-blue-600 dark:text-blue-500 hover:underline"><MdOutlineDelete className=' text-2xl' /></div>
                    :
                    <div title="Delete" onClick={() => handleDelete(product?.id)} className=" cursor-pointer font-medium text-blue-600 dark:text-blue-500 hover:underline"><MdOutlineDelete className=' text-2xl' /></div>
                    :
                    null}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
