import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IoMdCloseCircle } from "react-icons/io";
function Sidebar({ SidebarJSON }) {
    const location = useLocation();
    const navigate = useNavigate();
    const [navbarOpen, setNavbarOpen] = React.useState(false);

    const onClickMenu = (url) => {
        navigate(url);
        setTimeout(() => {
            // setNavbarOpen(!navbarOpen);
        }, 150);
    };

    const handleNavbar = () => {
        setNavbarOpen(!navbarOpen);
    }
    const [sideBar, setSideBar] = useState(false)
    let handleClick = () => {
        setSideBar((prev) => !prev)
    }
    let handleClick2 = () => {
        setSideBar(false)
    }

    return (
        <>
            <button className={` ${navbarOpen === true ? 'hidden' : 'flex'} absolute top-0 left-0 px-5 items-center justify-center border-r border-gray-200 text-gray-500 focus:outline-none focus:bg-gray-800 focus:text-gray-600 z-50 h-16 lg:hidden `} onClick={() => setNavbarOpen(!navbarOpen)}>
                <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h7">
                    </path>
                </svg>
            </button>
            <div className={`${navbarOpen === false ? 'hidden' : 'flex'} fixed left-0 inset-y-0 lg:static lg:flex  w-64 z-50 flex-col`} >
                <div className="flex flex-col w-64 relative flex-1 h-full max-h-full">
                    <div onClick={() => onClickMenu('/app/dashboard')} className="cursor-pointer relative flex items-center h-16 flex-shrink-0 px-4 bg-slate-400 border-b border-gray-200 w-full z-50">
                        <img src={`/${SidebarJSON.SidebarHeaderImage}`} alt="" className="rounded-full relative flex justify-center items-center mr-4" width={"60px"} />
                        <h1>
                            {SidebarJSON.SidebarHeaderName}
                        </h1>
                        <div onClick={handleNavbar} className="  items-end absolute right-3">

                            <IoMdCloseCircle className=" text-white lg:hidden block" />
                        </div>
                    </div>
                    <nav className="overflow-y-auto bg-slate-300 flex flex-col flex-wrap items-center justify-between relative w-64 z-10 px-3  h-full overflow-auto">
                        <div className="px-0 flex flex-col flex-wrap items-center w-full mx-auto flex-1">
                            <div className={"relative z-40 flex-1 w-full space-y-1"}>
                                {/* {SidebarJSON.WebView.map((ele, index) => {  })} */}
                                {/* return ( */}



                                <ul onClick={() => handleClick2()} className="md:flex-col md:min-w-full flex flex-col list-none" >
                                    <li className="items-center mt-1  rounded-full">
                                        <Link className={"text-sm capitalize p-2 rounded-full text-center font-bold block " + (location.pathname === "/app/dashboard" ? " bg-pink-500 text-white hover:text-lightBlue-600" : "text-blueGray-700 bg-[#FBB00C] h-full hover:text-[#000]")} to={"/app/dashboard"} >
                                            {" "} Dashboard
                                        </Link>
                                    </li>
                                </ul>

                                <div onClick={() => handleClick()} className="text-blueGray-700 cursor-pointer bg-[#FBB00C] text-sm capitalize p-2 rounded-full text-center font-bold block"> User Management </div>

                                {sideBar &&
                                    <ul className="md:flex-col md:min-w-full flex flex-col list-none" >
                                        <li className="items-center mt-1 whitespace-nowrap  rounded-full">
                                            <Link className={"text-sm capitalize p-2 hover:text-pink-500 rounded-full text-center font-bold block " + (location.pathname === "/app/userManagement" ? " text-white bg-blue-600 " : " ")} to={"/app/userManagement"} >
                                                {" "} Active User
                                            </Link>
                                        </li>
                                    </ul>}

                                {sideBar && <ul className="md:flex-col md:min-w-full flex flex-col list-none" >
                                    <li className="items-center mt-1 whitespace-nowrap  rounded-full">
                                        <Link className={"text-sm capitalize p-2 hover:text-pink-500 rounded-full text-center font-bold block " + (location.pathname === "/app/UserManagementDisable" ? " text-white bg-blue-600  " : " ")} to={"/app/UserManagementDisable"} >
                                            {" "} InActive User
                                        </Link>
                                    </li>
                                </ul>}






                                <ul onClick={() => handleClick2()} className="md:flex-col md:min-w-full flex flex-col list-none" >
                                    <li className="items-center mt-1  rounded-full">
                                        <Link className={"text-sm capitalize p-2 rounded-full text-center font-bold block " + (location.pathname === "/app/Question" ? " bg-pink-500 text-white hover:text-lightBlue-600" : "text-blueGray-700 bg-[#FBB00C] h-full hover:text-[#000]")} to={"/app/Question"} >
                                            {" "} Question
                                        </Link>
                                    </li>
                                </ul>

                                <ul onClick={() => handleClick2()} className="md:flex-col md:min-w-full flex flex-col list-none" >
                                    <li className="items-center mt-1  rounded-full">
                                        <Link className={"text-sm capitalize p-2 rounded-full text-center font-bold block " + (location.pathname === "/app/Tip" ? " bg-pink-500 text-white hover:text-lightBlue-600" : "text-blueGray-700 bg-[#FBB00C] h-full hover:text-[#000]")} to={"/app/Tip"} >
                                            {" "} Tip
                                        </Link>
                                    </li>
                                </ul>
                                {/* ) */}

                            </div>
                        </div>
                    </nav>
                </div >

            </div >
        </>
    );
}

export default Sidebar;
