export const QuestionConstants = {

    GET_MATCH_LIST_BY_ID_REQUEST:"GET_MATCH_LIST_BY_ID_REQUEST",
    GET_MATCH_LIST_BY_ID_SUCCESS:"GET_MATCH_LIST_BY_ID_SUCCESS",
    GET_MATCH_LIST_BY_ID_FAILURE:"GET_MATCH_LIST_BY_ID_FAILURE",


    GET_QUESTION_LIST_REQUEST: "GET_QUESTION_LIST_REQUEST",
    GET_QUESTION_LIST_SUCCESS: "GET_QUESTION_LIST_SUCCESS",
    GET_QUESTION_LIST_FAILURE: "GET_QUESTION_LIST_FAILURE",

    CREATE_QUESTION_REQUEST: "CREATE_QUESTION_REQUEST",
    CREATE_QUESTION_SUCCESS: "CREATE_QUESTION_SUCCESS",
    CREATE_QUESTION_FAILURE: "CREATE_QUESTION_FAILURE",

    UPDATE_QUESTION_LIST_REQUEST: "UPDATE_QUESTION_LIST_REQUEST",
    UPDATE_QUESTION_LIST_SUCCESS: "UPDATE_QUESTION_LIST_SUCCESS",
    UPDATE_QUESTION_LIST_FAILURE: "UPDATE_QUESTION_LIST_FAILURE",

    GET_TIP_LIST_REQUEST: "GET_TIP_LIST_REQUEST",
    GET_TIP_LIST_SUCCESS: "GET_TIP_LIST_SUCCESS",
    GET_TIP_LIST_FAILURE: "GET_TIP_LIST_FAILURE",

    GET_UPCOMING_MATCH_REQUEST: "GET_UPCOMING_MATCH_REQUEST",
    GET_UPCOMING_MATCH_SUCCESS: "GET_UPCOMING_MATCH_SUCCESS",
    GET_UPCOMING_MATCH_FAILURE: "GET_UPCOMING_MATCH_FAILURE",

    SUCCESS_CLEAR_LIVE_MATCH: "SUCCESS_CLEAR_LIVE_MATCH",
    SUCCESS_CLEAR_UPCOMMING_MATCH: "SUCCESS_CLEAR_UPCOMMING_MATCH",

    GET_LIVE_MATCH_REQUEST: "GET_LIVE_MATCH_REQUEST",
    GET_LIVE_MATCH_SUCCESS: "GET_LIVE_MATCH_SUCCESS",
    GET_LIVE_MATCH_FAILURE: "GET_LIVE_MATCH_FAILURE",

    CREATE_TIP_REQUEST: "CREATE_TIP_REQUEST",
    CREATE_TIP_SUCCESS: "CREATE_TIP_SUCCESS",
    CREATE_TIP_FAILURE: "CREATE_TIP_FAILURE",
    DELET_TIP_REQUEST: "DELET_TIP_REQUEST",
    DELET_TIP_SUCCESS: "DELET_TIP_SUCCESS",
    DELET_TIP_FAILURE: "DELET_TIP_FAILURE",

    LOGOUT: 'USERS_LOGOUT',

};
