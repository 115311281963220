import {
  userConstants
} from '../_constants';

export default function users(state = {}, action) {
  switch (action.type) {
    
    
    
    
    case userConstants.GET_USER_QUESTION_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_USER_QUESTION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        userquestion:action?.user
      };
    case userConstants.GET_USER_QUESTION_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    
case userConstants.UPDATE_USER_STATUS_REQUEST:
  return {
    ...state,
    loading: true
  };
case userConstants.UPDATE_USER_STATUS_SUCCESS:
  return {
    ...state,
    loading: false,
  };
case userConstants.UPDATE_USER_STATUS_FAILURE:
  return {
    ...state,
    loading: false,
    error: action.error
  };


    
    case userConstants.GET_USER_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_USER_LIST_SUCCESS:
      return {
        ...state,
      userList:action?.user?.data,
        loading: false,
      };
    case userConstants.GET_USER_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };









    case userConstants.LOGIN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case userConstants.LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return state
  }
}