import React from "react";
import { Route, Routes } from 'react-router-dom';
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import Dashboard from "../../pages/dashboard/Dashboard";
import layoutJSON from './layout.json'
import Question from "../../pages/Question/Question";
import Tip from "../../pages/Tip/Tip";
import ViewTip from "../../pages/ViewTip/ViewTip";
import UserManagement from "../../pages/UserManagement/UserManagement";
import TipByID from "../../pages/TipByID/TipByID";
import UserManagementDisable from "../../pages/UserManagementDisable/UserManagement";
import UserQuestion from "../../pages/UserQuestion/UserQuestion";

function Layout() {
  return (
    <div>
      <div className="overflow-hidden bg-gray-100">
        <div className="min-h-screen flex flex-col justify-center">
          <div className="h-screen flex overflow-hidden">
            <Sidebar SidebarJSON={layoutJSON.sidebarJSON} />
            <div className="flex flex-col w-0 flex-1 overflow-hidden">
              <Header HeaderJSON={layoutJSON.headerJSON} />
              <Routes>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/Question" element={<Question />} />
                <Route path="/Tip" element={<Tip />} />
                <Route path="/tips/:id" element={<TipByID/>} />
                <Route path="/userManagement" element={<UserManagement />} />
                <Route path="/UserManagementDisable" element={<UserManagementDisable />} />
                <Route path="/question/:id" element={<UserQuestion/>} />
                <Route path="/ViewTip" element={<ViewTip />} />
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Layout;
